<template>
<div>
    <NavTools />
        <div style="background-color:#fff" id="audio-list" v-cloak>
            <div class='container' style="max-width: 500px;">
                <div class="row" v-show="!showPlayer">
                    <div class='col-xs-12'>
                        <table class="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Last</th>
                                    <th>First</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-on:click="loadPlayer(record)" v-for="record in audioList" v-bind:key="record.dateReceived" v-bind:class="{'info': record.reviewStatus == '1', 'success': record.reviewStatus == '2'}">
                                    <td>{{record.dateReceived}}</td>
                                    <td>{{record.lastName}}</td>
                                    <td>{{record.firstName}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Player view - pops up on top -->
                <div v-if="showPlayer">
                    <div class="row">
                        <div class="col-xs-3">
                            <button class="btn btn-default" v-on:click="backToList()"><span class="glyphicon glyphicon-arrow-left"></span></button>
                        </div>
                        <div class="col-xs-9">
                            <h4>
                                {{record.dateReceived}} | {{record.lastName}}, {{record.firstName}}
                            </h4>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12" id="audioPlayerContainer">
                            <audio id="audioPlayer" controls style="width:100%" v-bind:src="record.audio_src + 'iv.mp3'" type="audio/mpeg"></audio>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 text-center">
                           Playback Speed
                        </div>
                        <div class="col-xs-12 text-center">
                            <div class="btn-group">
                                <button class="btn btn-default" v-on:click="setSpeed(1)" v-bind:class="playbackSpeed == 1 ? 'active' : ''">1.0x</button>
                                <button class="btn btn-default" v-on:click="setSpeed(1.5)" v-bind:class="playbackSpeed == 1.5 ? 'active' : ''">1.5x</button>
                                <button class="btn btn-default" v-on:click="setSpeed(2)" v-bind:class="playbackSpeed == 2 ? 'active' : ''">2.0x</button>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-xs-12 text-center">
                            Status
                        </div>
                        <div class="col-xs-12 text-center">
                            <div class="btn-group">
                                <!-- TODO: update these to be statuses -->
                                <button class="btn btn-default" v-on:click="updateReviewStatus(record, 0)" v-bind:class="record.reviewStatus == 0 ? 'active' : ''">Not Started</button>
                                <button class="btn btn-info" v-on:click="updateReviewStatus(record, 1)" v-bind:class="record.reviewStatus == 1 ? 'active' : ''">In Progress</button>
                                <button class="btn btn-success" v-on:click="updateReviewStatus(record, 2)" v-bind:class="record.reviewStatus == 2 ? 'active' : ''">Complete</button>
                            </div>
                        </div>
                    </div>

                    <div id="interview_questions_wrapper">
                        <div class='text-center' id='interview_questions_title'>
                            <span>INTERVIEW QUESTIONS</span>
                        </div>
                        <div id='questions' style="height: auto !important;margin-left:0;margin-right:0">
                            <ul style="list-style-type: none; margin-right: 1em; padding-left: 40px;">
                                <li v-for='q in record.questions' v-bind:key="q.time" v-on:click.prevent='selectQuestion(q)'>
                                    <div id='questionParent' v-bind:class="q.class + (q.index == currentVideoQuestionId ? ' live' : '')" v-bind:data-question-index='q.index'>
                                        <div class='number' v-bind:class="q.class">
                                            <p style="margin-left:1em; margin-top:-0.3em">{{q.index}}</p>
                                        </div>
                                        <div class='question'><b>({{ Math.floor(q.time / 60) }}:{{secondsEquation(q.time)}})</b> {{q.text}}</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div>
                        <!-- TODO NEXT: rating/notes? maybe not for now? -->
                    </div>                        

                </div>

            </div>
        </div>
</div>
</template>

<script>

    import Vue from 'vue';
    import VueResource from 'vue-resource';
    Vue.use(VueResource);

	require("@/utils/LoginRequired.js");

    // import moment from "moment";      <==Which one?==>
    import moment from 'moment-timezone';
    
    import { orgSupportMixin } from "../mixins/org-support-mixin-pack.js";

    import ivbus from "../utils/ivbus.js";

    import ionRangeSlider from "ion-rangeslider";

    import {
        ivAlertMixin,
        ivSpinnerMixin,
        ivUserInfoMixin,
        ivModalMixin,
        getParameterByName,
        setSessionStorageApiToken
    } from "../mixins/base-components-pack.js";

    import apiClient from "../utils/apiClient.js";    
    import NavTools from "../components/NavTools.vue";

    // function getParameterByName(name, url) {
    //     if (!url) url = window.location.href;
    //     name = name.replace(/[\[\]]/g, "\\$&");
    //     var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    //         results = regex.exec(url);
    //     if (!results) return null;
    //     if (!results[2]) return '';
    //     return decodeURIComponent(results[2].replace(/\+/g, " "));
    // }

    var vparam = getParameterByName('v');

    var urlAddVparam = "";
    if (vparam !== null) {
        urlAddVparam = "?v=" + vparam;
    } 

    export default {

        props: [
            'staticaddr',
            'serveraddr',
            'ameliaaddr',
            'lang',
            'lang_label',
        ],

        mixins: [ivAlertMixin, ivSpinnerMixin, ivUserInfoMixin, ivModalMixin, orgSupportMixin],

        components: {
            NavTools
        },

        data() {

            return {
    
                interview: { 
                    send: '',
                    sendId: '',
                    email: '', 
                    reviewStatus: 0,
                    reviewScore: null,  //user unique
                    reviewScoreAve: '4.5',
                    reviewComments: '',
                    other_reviews: [],
                },

                appEmail: '',
                current_school: '',
                applicationIds: [],

                guidelinesOpen: false,

                settings_school_type: 1,

                settings_school_type_options: [
                    { text: 'Graduate School', value: 0},
                    { text: 'Undergrad', value: 1},
                    { text: 'Boarding / Day School', value: 2},
                ],

                login: false,
                loginpassword: '',
                loginemail: '',
                password: '',

                user_status: "active",
                user_link: "None",
                
                urlVparam: urlAddVparam,
                audioList: [],
                record: {}, // selected recod
                showPlayer: false,
                playbackSpeed: 1,
                currentVideoQuestionId: null,
                
                }
            },

        mounted: function () {
            var that = this;
            // this will get called if we were already logged in
            ivbus.getFlagSticky('user', function () {
                that.login = true;
                that.loadReviewData();
            });
            
            apiClient.get("/api/interview/audio_list/").then(function(response) {
                Vue.set(that, "audioList", response.data.interviews);
            });
        },

        methods: {

            userStatus: function () {
                var that = this;
                console.log('inside userStatus');
                if (that.user) {
                    apiClient.get("/shield/mercury/user_status/", {params: {sendId: that.interview.send}}).then(function(response) {
                        Vue.set(that, "user_status", response.data.user_status);
                        Vue.set(that, "user_link", response.data.user_link);
                    });
                }
            },

            loadReviewData: function () {

                var that = this;

                apiClient.get("/api/user/user_info/").then(function(response) {
                    console.log(response.data);
                    if (response.data.status === false) {
                        that.login = false;
                    } else {
                        that.login = true;
                        if (response.data.organization) {	            		
                            // stay here

                            apiClient.get('/api/org/review/?send='+getParameterByName("v")).then(function(review) {
                                that.interview.reviewScore = review.data.data.rating;
                                that.interview.reviewScoreAve = review.data.data.rating_ave;
                                that.interview.reviewComments = review.data.data.comment;
                                that.interview.email = review.data.data.student_email;
                                that.interview.send = review.data.data.send;
                                Vue.set(that.interview, "other_reviews", review.data.data.other_reviews);
                                that.userStatus();

                                that.setupRating();
                            }).catch(function(error) {
                                console.log(error);
                            });

                        } else {
                            window.location = "/portal/";
                        }
                    }
                });
            },

            setupRating: function() {

                var r = this.interview.reviewScore;

                $('img').on('dragstart', function(event) { event.preventDefault(); });

                var parent = this;
                $("#range42").ionRangeSlider({
                    type: "single",
                    min: 1,
                    max: 5,
                    step: 0.5,
                    from: r,
                    keyboard: true,
                    hide_min_max: true,
                    hide_from_to: false,
                    grid: false,
                    onStart: function (data) {
                    },
                    onChange: function (data) {
                        // $("#click_below_to_rate").addClass("hide")
                        //console.log("onChange");
                    },
                    onFinish: function (data) {
                        $(".irs-single").removeClass("hide")
                        parent.updateStudentRating(data.from)
                        //console.log("onFinish"+ data.from);
                    },
                    onUpdate: function (data) {
                        //console.log("onUpdate");
                    }
                });

                var slider = $("#range42").data("ionRangeSlider");

                if (r == null) {
                    $(".irs-single").addClass("hide");	
                }

                // $(".irs-bar").addClass("hide");
                // $(".irs-bar-edge").addClass("hide");
                // $(".irs-slider").addClass("hide");

                // slider.update({
                //     from: r
                // });

            },

            // fetchOrgInfo: function() {
            // 	console.log('fetching organization' );
            // 	apiClient.get('/api/user/user_org_info/?v='+getParameterByName("v")).then(function(response) {
            // 	  	this.$set('orgName', response.data.org.org_name );
            // 	}).catch(function(error) {
            // 	  	console.log(error);
            // 	});
            // },

            updateStudentRating: function( newRating ) {
                var i = this.interview;
                i.reviewScore = newRating;
            },

            sendToFile: function() {
                var review = {
                    send: getParameterByName("v"),
                    rating: this.interview.reviewScore,
                    comment: this.interview.reviewComments
                }

                var that = this;

                var messageId = this.showMessage('saving changes...', 'alert-warning');
                apiClient.post('/api/org/review/', review).then(function() {
                    that.hideMessage(messageId);
                    that.showMessage('Changes Saved', 'alert-success', 3000);
                //setTimeout(function(){
                    window.close();
                //}, 0);
                }).catch(function(error) {

                });
            },

            user_login: function() {
                var that = this;
                var messageId = that.showMessage('Logging in...', 'alert-warning', that.uiMessageWaitLong);

                var spinId = that.spinStart();
                apiClient.post('/api/user/api-token-auth/', { username: that.loginemail, password: that.loginpassword }).then(function(response) {
                    that.spinStop(spinId);
                    that.hideMessage(messageId);
                    if (response.data.status === false) {
                        that.showMessage('Invalid email or password.',"alert-danger", that.uiMessageWaitLong);
                    } else {
                        if (response.data.token !== undefined) {
                            setSessionStorageApiToken(response.data.token)
                            that.login = true;
                            Vue.http.headers.common['Authorization'] = 'Token ' + sessionStorage.apitoken;	
                            that.loadReviewData();
                        } else {
                            //that.user_logout();
                        }
                    }
                }).catch(function(response) {
                    that.hideMessage(messageId);
                    that.showMessage('Invalid email or password.',"alert-danger", that.uiMessageWaitLong);
                    that.spinStop(spinId);
                    //that.user_logout();
                });
            },

                    loadPlayer: function (record) {
                Vue.set(this, "record", record);
                apiClient.get("/api/interview/audio_info/?v=" + record.sendId).then(function(response) {
                    Vue.set(record, "questions", response.data.questions);
                });
                this.showPlayer = true;
            },

            backToList: function () {
                this.showPlayer = false;
                $("#audioPlayer")[0].pause();			
            },

            setSpeed: function (speed) {
                this.playbackSpeed = speed;
                $("#audioPlayer")[0].playbackRate = speed;
            },

            selectQuestion: function(q) {
                $("#audioPlayer")[0].currentTime = q.time;
                $("#audioPlayer")[0].play();
            },

            updateReviewStatus: function(interview, status) {
                var that = this;
                var spinId = this.spinStart();
                apiClient.post('/api/org/review/status/', [{send: interview.id, status: status == 1 ? "in-progress" : status == 2 ? "complete" : "not-started"}]).then(function() {
                    interview.reviewStatus = status;				
                    that.spinStop(spinId);
                }).catch(function(error) {
                    that.spinStop(spinId);
                });
            },

            secondsEquation: function(time) {
            // prepend 0 if less than 10
            if ((time % 60) < 10) {
                return '0' + (time % 60);
            } else {
                return (time % 60);
            }
            }
        }
    }

</script>

<style lang="scss">
#viewfinder {
	width: 100%;
	transform: scale(-1, 1);
	border: 3px #e96724 dashed;
}

#player {
	background-color: #fff;
}

/*#interviewee_name {
	margin-top:0px;
	padding-left:20px;
	padding-bottom:0px;
	color: #333;
	font-size: 28px;
	text-align: left;
}
*/

/*#student-info-area {
	margin-bottom:40px;
}
*/

.tab-area-row {
	background-color: #E4E5E5;
	padding-top: 16px;
 	margin-top: 0;
    border: solid 2px #ccc;
    box-shadow: 0 0 15px #ccc;
    border-radius: 0 10px 10px 10px;
    padding: 10px;
    /*margin-top: -5px;*/
}

.tab-area-row-pdf {
	height: 850px;
}

.tab-area-row-pdf div {
	height: 790px;
}


.headshot {
	min-width: 52px;
	width: 52px;
	height: 52px;
}

.tab-header.tab-header-active {
    box-shadow: 0 15px 0 -2px #E4E5E5, 0 0 15px #ccc;	
    border: solid 2px #ccc;
    border-bottom: 0;
}

.tab-header {
    border-radius: 10px 10px 0 0;
    padding: 10px;
    text-align:center;
    border: solid 1px #ccc;
    border-bottom: 0;
}

@media (max-width: 768px) {
	.col-xs-12 { 
		padding-left:0;
		padding-right:0;
	}

	.question {
		margin-left:5px;
		width:100%;	
	}

	#questions {
		margin-left: 0;
	    margin-right: 0;
	}
}

@media (max-width:992px) {
	#interview_questions_wrapper {
		margin-top:10px;	
	}	
}


/* ELEVEATOR PITCH STYLING */
.ep-recording-header {
    height: 200px;
    margin-top: 15px;
    max-width: 1200px;
    background-color: #e96724;
    border-radius: 15px;
    color: white;
    padding: 25px;
}

.ep-recording-body {
    width: 90%;
    margin-left: 5%;
    margin-top: -6%;
    margin-bottom:150px;
    background-color: white;
    padding: 5%;
    border: solid 2px #e96724;
}

.ep-compare {
	font-size: 200%;
	text-align: center;
    display: block;
}
.check {
	color:#e96724;
}
.x {
	color:grey;
}



.orange-font {
	color:#e96724;
}

</style>